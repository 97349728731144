
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';
import { EventClient } from '@/services/Services';
import EventCard from '../components/eventCard.vue';
import store from "@/store";
import * as OM from "@/model";

@Options({
    components: {
        EventCard
    }
})
export default class DayEvents extends Vue {

    selectedDay: Date = new Date();    
    model: OM.CalendarFIlterVM = new OM.CalendarFIlterVM();
    events: OM.EventVm[] = [];
    communityTitle: string = "";

    @Prop({
        default: ""
    }) communityIdentifier: string;

    @Prop({
        default: ""
    }) dateTime: string;

    created() {
        if(this.communityIdentifier == store.state.community.identifier)
            this.communityTitle = store.state.community.title;

        this.selectedDay =  getUniversalISOStringDate(getUniversalDate(this.dateTime));
        this.model.communityIdentifier = this.communityIdentifier;
        this.model.date = <any>this.selectedDay;

        EventClient.getByDayAsEventList(this.model)
        .then(x => {
            this.events = x;
        })
    }

    openEvent(item: OM.EventVm) {
        this.$emit('close');
        this.$router.push("/events/" + item.identifier);
    }

}
