
import { Options, Vue, } from 'vue-class-component';
import { getUniversalISOStringDate } from '@/utils';
import calendar from './components/calendar.vue';
import CreateEvent from './modals/createEvent.vue';
import DayEvents from './modals/dayEvents.vue';

@Options({
    components: {
        calendar
    }
})
export default class Calendar extends Vue {

    selectedMonth: Date = new Date();

    created() {}

    changeMonth(prev: boolean) {
        if(prev)
            this.selectedMonth = new Date(this.selectedMonth.setMonth(this.selectedMonth.getMonth() - 1));
        else
            this.selectedMonth = new Date(this.selectedMonth.setMonth(this.selectedMonth.getMonth() + 1));        
    }

    dayClicked(day: Date) {
        // this.$router.push("/communities/" + this.$route.params.identifier + "/calendar/" + getUniversalISOStringDate(day));
        this.$opModal.show(DayEvents, {
            communityIdentifier: this.$route.params.identifier,
            dateTime: getUniversalISOStringDate(day)
        })
    }

    newEvent() {
        this.$opModal.show(CreateEvent, {
            communityIdentifier: this.$route.params.identifier
        });
    }

}
